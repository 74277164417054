import React from 'react'

import Layout from '../components/layout'

import vb1 from '../images/vb1.jpg'
import bio1 from '../images/bio1.jpg'
import bio2 from '../images/bio2.jpg'

const BioPage = () => {
  return (
    <Layout>
      <div className='pageTitle'>Biography</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      <div className='text-gray-700 mx-auto pb-8 md:max-w-xl lg:max-w-3xl xl:max-w-6xl'>
        <img className='mx-auto py-4' src={vb1} alt='' />
        <div className='mx-4'>
          <p className='my-4'>
            Velvet Brown is profoundly passionate about teaching and is a
            Distinguished Professor of Music at the Pennsylvania State
            University. She teaches applied tuba and euphonium and coordinates
            the brass chamber music program. Many of her students have won
            prestigious playing and teaching positions and were prizewinners at
            various regional, national and international competitions. Brown
            also serves as the Associate Director of the School of Music for
            Equity, Diversity and Inclusion.
          </p>
          <p className='my-4'>
            Velvet Brown enjoys a career as professor, international soloist,
            chamber ensemble performer, recording artist, conductor, and
            orchestral player. She has performed and taught in Italy,
            Switzerland, Austria, Germany, Finland, France, England, Hungary,
            Slovenia, Russia, Japan, Cuba, Canada and the United States. Ms.
            Brown is currently the principal tubist of the New Hampshire Music
            Festival Orchestra. Brown was honored to be principal tuba with the
            Hope and Harmony Ensemble which recorded a video of fanfares for the
            2021 Presidential Inauguration conducted by Marin Alsop. She has
            served as principal tuba with the River City Brass band, guest
            principal with the Lahti Philharmonic in Finland and as substitute
            or additional tubist with the Detroit Symphony, Saint Louis
            Symphony, San Francisco Women's Philharmonic Orchestra, and the Fort
            Wayne Philharmonic. Having performed and worked closely with jazz
            and commercial artists regarded as some of the most influential
            musicians of all time, including Howard Johnson, Bob Stewart and Doc
            Severinsen, she felt inspired to explore new territories in her
            music career. As a consequence, influenced also by some personal
            experiences, she decided to explore in music how life events shape
            one's creativity and thus started a veritable music renaissance,
            which involves soul-searching musical patterns and a more profound
            relationship with the audience. She is the co- founder of MOJATUBA:
            Tuba and Dance Fusion Project, and is in her 15th year as lead tuba
            with Howard Johnson's "Gravity" Tuba Jazz Ensemble.
          </p>
          <div className='flex flex-row justify-center py-4'>
            <img src={bio1} className='px-8 max-w-sm lg:max-w-lg' alt='' />
            <img
              src={bio2}
              className='hidden px-8 max-w-sm lg:block lg:max-w-lg'
              alt=''
            />
          </div>
          <p className='my-4'>
            Velvet Brown has been an invited solo artist or tuba solo artist
            adjudicator at the International Tuba and Euphonium Conferences
            since 1998. She has also served as guest conductor for festivals in
            the United States in Maine, Kentucky, New Hampshire, Massachusetts,
            Ohio, Indiana, and Pennsylvania as well as abroad in Austria, Italy,
            Slovenia, Japan, and Canada. Brown is also honored to be Visiting
            Artist Faculty at the Peabody Institute at Johns Hopkins University.
            Prior to her tenure at Penn State, she also taught at Bowling Green
            State University (Ohio), Ball State University (Indiana), and served
            as an associate director of University Bands at Boston University.
          </p>
          <p className='my-4'>
            Brown has garnered high praise as a founding and current member of
            Stiletto Brass Quintet, Monarch Brass Quintet and Brass Ensemble,
            the Quintet of Americas and the Pennsylvania Brassworks. Moreover,
            she is also a founding board member of the International Women's
            Brass Conference.
          </p>
          <p className='my-4'>
            She has released four solo CDs, and has collaborated on several
            other recordings as a soloist and ensemble member, such as Stiletto
            Brass Quintet featuring Doc Severinsen, trumpet, on one of her
            original compositions, Testimony (Howard Johnson and Gravity) as
            lead tuba, and a solo tuba jazz recording with the Cleveland Jazz
            Ensemble with Paul Ferguson, director. Velvet Brown is a
            Buffet/Crampon Performing Artist, performing on the MW 2250 and 2182
            F tubas, and the MW 2000 and MW 3450 C tubas. She is also a
            performing artist for Denis Wick mouthpieces, mutes, and
            accessories.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default BioPage
